export const ASSIGN_DEALER_TITLE = "Assign to dealer";
export const ASSIGN_PARENT_TITLE = "Parent name";
export const ASSIGN_TO_TITLE = "Assign event to";

export const ADD_SALES_REP_TITLE = "Add Sales representative";
export const EDIT_SALES_REP_TITLE = "Edit personal information";

export const ADD_PARENT_TITLE = "Add Parent";
export const EDIT_PARENT_TITLE = "Edit personal information";

export const ADD_DEALER_TITLE = "Add Dealer";
export const EDIT_DEALER_TITLE = "Edit personal information";

export const ADD_END_USER_TITLE = "Add End-user";
export const EDIT_END_USER_TITLE = "Edit personal information";

export const PARENT_GENERATE_LIST_TITLE = "Generate list of parents";
export const DEALER_GENERATE_LIST_TITLE = "Generate list of dealers";
export const SALES_REP_GENERATE_LIST_TITLE =
  "Generate list of sales representatives";
export const MEASUREMENTS_GENERATE_LIST_TITLE = "Generate list of measurements";

export const DELETE_USER_TITLE = "Delete User";
export const DELETE_USER_BODY = "Are you sure you want to delete this user?";
export const DELETE_USER_EVENT_BODY =
  "Are you sure you want to delete this user from the current event?";

export const ADD_PRODUCT_TYPE_TITLE = "Add new product type";
export const EDIT_PRODUCT_TYPE_TITLE = "Edit product type";

export const MEASUREMENTS_TYPES_TITLE = "Measurement types";

export const SHOW_ALL_UPLOADS_TITLE = "Show all uploads";
export const UPLOAD_FILE_TITLE = "Upload excel";
