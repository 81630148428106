import React, { FC, useEffect, useMemo, useState } from "react";
import {
  ICalculateExcelsComponent,
  CalculateTabsData,
  IGenderForUploadExcels,
  CalculateFormData,
  IRecommendedSizesGroup,
} from "@models/components/secondary/uploadedexcelfilesmodal";
import { Button } from "@components/Button";
import { FormProvider, useForm } from "react-hook-form";
import {
  FILE_FAULTED_FORMULAS_NOTIFICATION,
  simpleGenders,
  SOMETHING_WENT_WRONG_NOTIFICATION,
  UPLOADED_AND_SAVED_NOTIFICATION,
} from "@constants/commons";
import { Input } from "@components/Input";
import { calculateRecommendedSize, saveUploadedExcel } from "@api/types";
import { getFormattedError } from "@services/http";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { IUploadExcelParameter } from "@models/common/measurements";
import ReactDOM from "react-dom";
import { AlertModal } from "@components/AlertModal";
import {
  CLOSE_UPLOAD_FILE_BODY,
  CLOSE_UPLOAD_FILE_TITLE,
} from "@constants/measurements";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { typesConfirmation } from "@store/selectors/types";
import { typesSlice } from "@store/reducers/TypesSlice";

export const CalculateExcel: FC<ICalculateExcelsComponent> = ({
  showNotification,
  uploadedData,
  handleModalOpen,
}) => {
  const typesStoreConfirmation = useAppSelector(typesConfirmation);
  const formMethods = useForm<CalculateFormData>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
  });
  const { handleSubmit } = formMethods;
  const dispatch = useAppDispatch();

  const [smplGenders, setSmplGenders] =
    useState<IGenderForUploadExcels[]>(simpleGenders);
  const [isGendersGroupUpdated, setIsGendersGroupUpdated] = useState(false);
  const [recommendedSizes, setRecommendedSizes] =
    useState<IRecommendedSizesGroup>({ MALE: [], FEMALE: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [closeWindowOpen, setCloseWindowOpen] = useState(false);

  const activeGender = useMemo(() => {
    return smplGenders.find((g) => g.active)?.value || "MALE";
  }, [smplGenders]);
  const isRecommendedSize = recommendedSizes[activeGender].length !== 0;

  useEffect(() => {
    if (uploadedData && !isGendersGroupUpdated) {
      setSmplGenders((prevState) => {
        const correctGenders = prevState.filter(
          (group) => uploadedData.recommendedSizeColumns[group.value]
        );
        if (
          correctGenders.length === 1 &&
          correctGenders[0].value === "FEMALE"
        ) {
          correctGenders[0].active = true;
        }
        return correctGenders;
      });
      setIsGendersGroupUpdated(true);
    }
  }, [uploadedData]);

  const handleSelectGender = (id: number) => {
    setSmplGenders((prevState) =>
      prevState.map((prevG) => {
        if (prevG.id === id) {
          return { ...prevG, active: true };
        }
        return { ...prevG, active: false };
      })
    );
  };

  const onSubmitCalculate = async (formData: CalculateFormData) => {
    setIsLoading(true);
    try {
      const organizedData = Object.keys(formData).reduce((acc, fieldName) => {
        const [tabId, ...fieldParts] = fieldName.split("_");
        const field = fieldParts.join("_");

        if (!(fieldName in formData)) {
          return acc;
        }

        return {
          ...acc,
          [tabId]: {
            ...acc[tabId as keyof CalculateTabsData],
            [field]: formData[fieldName as keyof CalculateFormData],
          },
        };
      }, {} as CalculateTabsData);
      const recommendedSizesData = await calculateRecommendedSize(
        dispatch,
        (uploadedData as IUploadExcelParameter).templateId,
        { ...organizedData[activeGender], Gender: activeGender }
      );

      setRecommendedSizes((prevState) => {
        return {
          ...prevState,
          [activeGender]: Object.entries(recommendedSizesData).map(
            ([name, value]) => ({
              name,
              value,
            })
          ),
        };
      });
    } catch (error) {
      showNotification(FILE_FAULTED_FORMULAS_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = async () => {
    setIsLoading(true);
    const data = uploadedData as IUploadExcelParameter & {
      productTypeId: string;
    };
    try {
      await saveUploadedExcel(dispatch, data.templateId, data.productTypeId);
      showNotification(UPLOADED_AND_SAVED_NOTIFICATION, "success");
      setTimeout(() => handleModalOpen(), 1000);
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      setIsLoading(false);
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleCloseAllModal = () => {
    dispatch(typesSlice.actions.cleanConfirmation());
    handleModalOpen();
  };

  const handleOpenConfirmCancelModal = () => {
    if (typesStoreConfirmation) setCloseWindowOpen(true);
    else handleModalOpen();
  };

  return (
    <>
      {closeWindowOpen &&
        ReactDOM.createPortal(
          <AlertModal
            type="delete"
            title={CLOSE_UPLOAD_FILE_TITLE}
            body={CLOSE_UPLOAD_FILE_BODY}
            handleOpen={() => setCloseWindowOpen(false)}
            handleConfirm={handleCloseAllModal}
            id="close"
          />,
          document.body
        )}
      <form onSubmit={handleSubmit(onSubmitCalculate)}>
        <FormProvider {...formMethods}>
          {uploadedData && (
            <div className="loading-file-block">
              <div className="calculate-file-line" />
              <p className="modal-description modal-description-inside">
                Check that measurements work correctly
              </p>
              <p
                className="form-label form-custom-file-label"
                style={{ marginBottom: "8px" }}
              >
                Gender*
              </p>
              <ul className="segment">
                {smplGenders.map((gender) => (
                  <li
                    key={gender.id}
                    className={`segment-item ${gender.active && "isActive"}`}
                    onClick={() => handleSelectGender(gender.id)}
                  >
                    {gender.value}
                  </li>
                ))}
              </ul>
              <div className="loading-file-block-inputs-box">
                {uploadedData.recommendedSizeColumns[activeGender]?.map(
                  ({ measurementParam: { name } }) => (
                    <Input
                      label={name}
                      key={`${activeGender}_${name}`}
                      name={`${activeGender}_${name}`}
                    />
                  )
                )}
              </div>
              <div className="calculate-file-line" />
              <div
                className={
                  isRecommendedSize
                    ? "loading-file-block-inputs-box recommended-box"
                    : "loading-file-block-calculate-box"
                }
              >
                {recommendedSizes[activeGender].map(({ name, value }, ind) => (
                  <Input
                    readOnly={true}
                    label={name}
                    value={value}
                    key={`${name}_${value}_${ind}`}
                    name={`${name}_${value}_${ind}`}
                  />
                ))}
                <Button
                  text="Calculate"
                  className={`btn btn-main${
                    isRecommendedSize
                      ? " recommended-btn"
                      : " loading-file-block-calculate-box-btn"
                  }`}
                  type="submit"
                  disable={isLoading}
                />
              </div>
            </div>
          )}
        </FormProvider>
        <div className="modal-footer">
          <Button
            type="button"
            text="Cancel"
            className="btn btn-primary"
            onClick={handleOpenConfirmCancelModal}
          />
          <Button
            text="Save"
            className="btn btn-main"
            type="button"
            disable={!uploadedData || isLoading}
            onClick={onSave}
          />
        </div>
      </form>
    </>
  );
};
