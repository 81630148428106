import { FC, useEffect, useRef, useState } from "react";
import { IProductTypeDropdown } from "@models/components/secondary/dropdownmenu";
import { useFormContext } from "react-hook-form";
import { REQUIRED_FIELD } from "@constants/validation";
import { DebounceInput } from "react-debounce-input";
import { PRODUCT_TYPE_TITLE } from "@constants/events";
import { ProductType } from "@models/common/events";
import { setDropdownItems } from "@services/common";

export const ProductTypeDropdown: FC<IProductTypeDropdown> = (props) => {
  const {
    name,
    pattern,
    id,
    error,
    subLabel,
    limit,
    defaultValue,
    dropdownList,
    searchedValue,
    handleSearchedValue,
    disable,
    required,
    clear,
    autocomplete = "on",
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [productTypesDropdownItems, setProductTypesDropdownItems] = useState(
    defaultValue || []
  );

  const [isOpen, setOpen] = useState(false);
  const [focusedFields, setFocused] = useState({
    [name]: false,
  });

  const focuseField = (fieldName: string) => {
    setFocused({ [fieldName]: true });
  };

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  const formData = useFormContext();

  const { watch, clearErrors, setError, formState, setValue } = formData;
  const { errors } = formState;

  useEffect(() => {
    if (!productTypesDropdownItems.length && defaultValue) {
      setProductTypesDropdownItems(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (clear) {
      setProductTypesDropdownItems([]);
      setOpen(false);
    }
  }, [clear]);

  useEffect(() => {
    if (focusedFields?.[name]) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [focusedFields]);

  useEffect(() => {
    if (!!watch()?.[name]?.length) {
      clearErrors(name);
    }
  }, [watch()?.[name]]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(e.target as HTMLDivElement)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setValue(
      name,
      productTypesDropdownItems.map((item) => item.productTypeId).join(", ")
    );

    if (!productTypesDropdownItems?.length && focusedFields[name]) {
      setError(name, { type: "custom", message: REQUIRED_FIELD });
    } else {
      clearErrors(name);
    }
  }, [productTypesDropdownItems]);

  useEffect(() => {
    if (focusedFields[name]) {
      clearErrors(name);
    }
  }, [focusedFields]);

  const handleProductTypeDropdownItems = (items: ProductType[]) => {
    setProductTypesDropdownItems(items);
  };

  const handleDropdownItems = (value: ProductType) => {
    setDropdownItems({
      title: PRODUCT_TYPE_TITLE,
      limit,
      productTypes: productTypesDropdownItems,
      handleProductTypes: handleProductTypeDropdownItems,
      value,
      dropdownOpen: () => limit && setOpen(false),
      handleSearchedValue,
    });
  };

  return (
    <>
      <div className={`dropdown form ${isOpen && "isActive"}`}>
        <label htmlFor="dropdown" className="form-label">
          {PRODUCT_TYPE_TITLE} {required && <sup>*</sup>}
        </label>
        <div ref={ref}>
          <div className="dropdown-form">
            <div
              className={`dropdown-input-container ${
                !!errors?.[name]?.message &&
                !focusedFields[name] &&
                "form-input-error"
              }`}
            >
              {productTypesDropdownItems.map((item) => (
                <button
                  disabled={disable}
                  className="dropdown-selected"
                  key={item.productTypeId}
                  onClick={(e) => e.preventDefault()}
                >
                  {item.name}
                  <i
                    className="dropdown-selected-icon icon icon-round-close "
                    onClick={() => {
                      setProductTypesDropdownItems((prevItems) => [
                        ...prevItems.filter(
                          (prevItem) =>
                            prevItem.productTypeId !== item.productTypeId
                        ),
                      ]);
                    }}
                  />
                </button>
              ))}
              <DebounceInput
                id={id}
                {...(formData?.register
                  ? formData.register(name, { pattern })
                  : {})}
                name={name}
                type="text"
                placeholder="Select product type"
                className={`dropdown-input ${
                  !!errors?.[name]?.message &&
                  !focusedFields[name] &&
                  "form-input-error"
                }`}
                onFocus={() => focuseField(name)}
                debounceTimeout={500}
                autoComplete={autocomplete}
                onChange={(event) => {
                  handleSearchedValue(event.target.value);
                }}
                value={searchedValue}
                disabled={disable}
              />
            </div>
            <button
              className="dropdown-btn"
              onClick={toggleDropdown}
              disabled={disable}
            >
              <i className={`dropdown-icon icon icon-arrow`} />
            </button>
          </div>
          <ul className="dropdown-list">
            {!!dropdownList?.length ? (
              dropdownList.map((value) => (
                <li
                  className={`dropdown-item ${
                    value.productTypeId ===
                      productTypesDropdownItems.find(
                        (item) => item.productTypeId === value.productTypeId
                      )?.productTypeId && "isActive"
                  }`}
                  onClick={() => handleDropdownItems(value)}
                  key={value.productTypeId}
                >
                  {value.name}
                </li>
              ))
            ) : (
              <li className="dropdown-item">
                <span className="dropdown-no-result">No result found.</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      {subLabel && (error || errors?.[name]?.message) && (
        <label
          htmlFor={id}
          className={`form-sublabel ${
            (error || !!errors?.[name]?.message) && "form-input-error"
          }`}
        >
          <>{subLabel || errors?.[name]?.message}</>
        </label>
      )}
    </>
  );
};
