import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import {
  IUploadedExcelsComponent,
  IUploadExcelForm,
} from "@models/components/secondary/uploadedexcelfilesmodal";
import { DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION } from "@constants/commons";
import { ProductTypeDropdown } from "@components/Dropdown/ProductTypeDropdown";
import { ProductType } from "@models/common/events";
import { getProductTypesBySearchKey } from "@api/types";
import { getFormattedError } from "@services/http";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { Button } from "@components/Button";
import { uploadExcelParam } from "@api/types";
import { typesSlice } from "@store/reducers/TypesSlice";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { typesConfirmation } from "@store/selectors/types";

export const UploadExcel: FC<IUploadedExcelsComponent> = ({
  setUploadedData,
  listUploadedExcels,
}) => {
  const [listProductTypes, setListProductTypes] = useState<ProductType[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const [searchedType, setSearchedType] = useState("");
  const [productTypes, setProductTypes] = useState<ProductType[]>();
  const dispatch = useAppDispatch();
  const typesStoreConfirmation = useAppSelector(typesConfirmation);

  const formMethods = useForm<IUploadExcelForm>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
  });
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty },
    setError,
    clearErrors,
  } = formMethods;
  const selectedTemplate = watch("template");
  const selectedType = watch("productTypes");

  useEffect(() => {
    if (isDirty || selectedType) {
      if (!typesStoreConfirmation) {
        dispatch(typesSlice.actions.setCleanConfirmation());
      }
    } else {
      if (typesStoreConfirmation) {
        dispatch(typesSlice.actions.cleanConfirmation());
      }
    }
  }, [isDirty, selectedType, typesStoreConfirmation]);

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        const types = await getProductTypesBySearchKey(
          dispatch,
          searchedType,
          DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION
        );
        setListProductTypes(types.content);
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, [searchedType]);

  useEffect(() => {
    if (listUploadedExcels && selectedTemplate?.[0]) {
      const isExistingFileName = listUploadedExcels.find(
        (item) => item.templateName === selectedTemplate?.[0].name
      );

      if (isExistingFileName) {
        setError("template", {
          message: `${selectedTemplate?.[0].name} already existing.`,
        });
      } else {
        clearErrors("template");
      }
    }
  }, [listUploadedExcels, selectedTemplate]);

  const onSubmitUpload = async (data: IUploadExcelForm) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("template", data.template[0]);
      formData.append("productTypeId", data.productTypes);
      const uploadedData = await uploadExcelParam(dispatch, formData);
      setUploadedData({ ...uploadedData, productTypeId: selectedType });
      setIsDataUploaded(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        const serverError = error.response?.data;
        setError("template", {
          message: serverError.message,
        });
      }
      return Promise.reject(getFormattedError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitUpload)}>
        <FormProvider {...formMethods}>
          <div className="modal-wrapper">
            <div
              className={`form modal-form${
                (isLoading || isDataUploaded) && " isDisabled"
              }`}
              style={{ flex: 1 }}
            >
              <ProductTypeDropdown
                disable={isLoading || isDataUploaded}
                name="productTypes"
                id="dropdown"
                limit={1}
                defaultValue={productTypes}
                dropdownList={listProductTypes}
                searchedValue={searchedType}
                handleProductTypeItems={(items) => setProductTypes(items)}
                handleSearchedValue={(type) => setSearchedType(type)}
                autocomplete="off"
                required
              />
              <div className="form-custom-file">
                <p className="form-label form-custom-file-label">
                  Upload XLSX file*
                </p>
                <label className="form-custom-file-input-box">
                  <div
                    className={`form-input form-custom-file-input ${
                      errors.template && "form-input-error"
                    }${(isLoading || isDataUploaded) && " is-disabled"}`}
                  >
                    {selectedTemplate?.[0]?.name || ""}
                  </div>
                  {errors.template && (
                    <span className="form-sublabel form-error">
                      {errors.template.message}
                    </span>
                  )}
                  <input
                    disabled={isLoading || isDataUploaded}
                    type="file"
                    accept=".xlsx"
                    {...register("template", {
                      validate: {
                        checkDuplicate: () => {
                          if (errors.template) {
                            return errors.template.message;
                          }
                          return true;
                        },
                      },
                    })}
                    hidden
                  />
                </label>
                <Button
                  disable={
                    !selectedType ||
                    !selectedTemplate?.[0] ||
                    !!errors.template ||
                    isLoading ||
                    isDataUploaded
                  }
                  text="Upload"
                  className="btn btn-main form-custom-file-submit"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </FormProvider>
      </form>
      {isLoading && (
        <div className="loading-file-block">
          <div className="calculate-file-line" />
          <p className="loading-file-block-description">
            <i className="load-loader loader icon" />
            <span style={{ flex: 1 }}>Checking file, please wait...</span>
          </p>
        </div>
      )}
    </>
  );
};
