import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EventState,
  IAddMeasurementsData,
  IAddMeasurementsErrors,
  IEvent,
} from "@models/common/events";
import { foundMeasurementsStoreIndexes } from "@services/common";
import { ISelectedRecommendedSizesStore } from "@models/components/secondary/measurementsmodal";

const initialState: EventState = {
  type: "",
  payload: undefined,
  addMeasurementsErrors: [],
  addMeasurementsData: [],
  addMeasurementsSelectedSizes: [],
};

export const eventInfoSlice = createSlice({
  name: "eventInfo",
  initialState,
  reducers: {
    setEventData: (state, { payload }: PayloadAction<IEvent>) => {
      state.payload = payload;
    },
    clearEventData: (state) => {
      state.payload = undefined;
    },
    setAddMeasurementsData: (
      state,
      { payload }: PayloadAction<IAddMeasurementsData>
    ) => {
      const { dataIndex, updatedErrorsStore } = foundMeasurementsStoreIndexes(
        state.addMeasurementsData,
        state.addMeasurementsErrors,
        payload.productTypeId,
        payload.gender
      );
      if (dataIndex !== -1) {
        state.addMeasurementsData = state.addMeasurementsData.map(
          (item, index) => {
            if (dataIndex === index) {
              return { ...item, data: payload.data };
            }
            return item;
          }
        );
      } else {
        state.addMeasurementsData = [...state.addMeasurementsData, payload];
      }

      state.addMeasurementsErrors = updatedErrorsStore;
    },
    setAddMeasurementsErrors: (
      state,
      { payload }: PayloadAction<IAddMeasurementsErrors>
    ) => {
      const { errorIndex, updatedDataStore } = foundMeasurementsStoreIndexes(
        state.addMeasurementsData,
        state.addMeasurementsErrors,
        payload.productTypeId,
        payload.gender
      );
      if (errorIndex !== -1) {
        state.addMeasurementsErrors = state.addMeasurementsErrors.map(
          (item, index) => {
            if (errorIndex === index) {
              return { ...item, message: payload.message };
            }
            return item;
          }
        );
      } else {
        state.addMeasurementsErrors = [...state.addMeasurementsErrors, payload];
      }

      state.addMeasurementsData = updatedDataStore;
    },
    setSelectedSizesData: (
      state,
      { payload }: PayloadAction<ISelectedRecommendedSizesStore>
    ) => {
      const productTypeIndex = state.addMeasurementsSelectedSizes.findIndex(
        (item) => item.productTypeId === payload.productTypeId
      );
      if (productTypeIndex !== -1) {
        state.addMeasurementsSelectedSizes = state.addMeasurementsSelectedSizes
          .map((currentType, index) => {
            if (index === productTypeIndex) {
              return payload;
            }
            return currentType;
          })
          .filter((item) => item.selectedSize.length > 0);
      } else {
        state.addMeasurementsSelectedSizes = [
          ...state.addMeasurementsSelectedSizes,
          payload,
        ];
      }
    },
    clearAddMeasurementsDataAndErrors: (state) => {
      state.addMeasurementsData = [];
      state.addMeasurementsErrors = [];
      state.addMeasurementsSelectedSizes = [];
    },
  },
});

export default eventInfoSlice.reducer;
