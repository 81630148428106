import { FC, useEffect, useMemo, useRef, useState } from "react";
import { getFormattedError } from "@services/http";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useFormContext } from "react-hook-form";
import { eventInfoSlice } from "@store/reducers/EventSlice";
import {
  IMeasurementParamId,
  IRecommendedSizesParams,
} from "@models/components/secondary/measurementsmodal";
import { calculateRecommendedSize } from "@api/types";
import { COULDNT_CALCULATE_SIZE } from "@constants/commons";

export const RecommendedSizesProcessing: FC<IRecommendedSizesParams> = ({
  gender,
  productTypeId,
  templateId,
  measurementParams,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [prevDataForCalculate, setPrevDataForCalculate] = useState<
    IMeasurementParamId[] | null
  >(null);
  const dispatch = useAppDispatch();
  const { watch } = useFormContext();
  const measurementValues = watch("measurementData");

  const measurementParamIds = measurementParams.map(
    (item) => item.measurementParamId
  );

  const measurementData: IMeasurementParamId[] | null = measurementValues
    ? Object.entries(measurementValues)
        .map(([measurementParamId, value = ""]) => ({
          measurementParamId,
          value: value as string,
          name:
            measurementParams.find(
              (item) => item.measurementParamId === measurementParamId
            )?.name || "",
        }))
        .filter((item) => measurementParamIds.includes(item.measurementParamId))
    : null;

  const isCanCalculateSize = useMemo(() => {
    if (!measurementData) {
      return false;
    }
    return !measurementData.find((field) => field.value.trim() === "");
  }, [measurementData]);

  useEffect(() => {
    const suitableArray = prevDataForCalculate?.filter(
      (item, index) => item.value !== measurementData?.[index].value
    ) || ["1"];

    if (isCanCalculateSize && measurementData && suitableArray.length > 0) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(async () => {
        try {
          const dataToSend = measurementData.reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
          }, {} as { [key: string]: string });
          setPrevDataForCalculate(measurementData);
          const recommendedSizesData = await calculateRecommendedSize(
            dispatch,
            templateId,
            { ...dataToSend, Gender: gender }
          );
          dispatch(
            eventInfoSlice.actions.setAddMeasurementsData({
              productTypeId,
              data: recommendedSizesData,
              gender,
            })
          );
        } catch (error) {
          dispatch(
            eventInfoSlice.actions.setAddMeasurementsErrors({
              productTypeId,
              message: COULDNT_CALCULATE_SIZE,
              gender,
            })
          );
          return Promise.reject(getFormattedError(error));
        }
      }, 1000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isCanCalculateSize, measurementData, prevDataForCalculate]);

  return null;
};
