import React, { FC, useEffect, useState } from "react";
import { PortalModal } from "@components/PortalModal";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import {
  IUploadedExcel,
  IViewUploadedExcelsModal,
} from "@models/components/secondary/uploadedexcelfilesmodal";
import { UploadExcel, CalculateExcel } from "./components";
import { getUploadedExcels } from "@api/types";
import { SMALL_ITEMS_COUNT_WITHOUT_PAGINATION } from "@constants/commons";
import { getFormattedError } from "@services/http";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { IUploadExcelParameter } from "@models/common/measurements";

export const UploadExcelModal: FC<IViewUploadedExcelsModal> = ({
  title,
  handleModalOpen,
}) => {
  const [listUploadedExcels, setListUploadedExcels] = useState<
    IUploadedExcel[] | null
  >(null);
  const [uploadedData, setUploadedData] = useState<
    (IUploadExcelParameter & { productTypeId: string }) | null
  >(null);
  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        const uploadedExcels = await getUploadedExcels(
          dispatch,
          SMALL_ITEMS_COUNT_WITHOUT_PAGINATION,
          0
        );
        setListUploadedExcels(uploadedExcels.content);
      } catch (error) {
        handleModalOpen();
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, []);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      <PortalModal
        title={title}
        isOpen={true}
        onClose={handleModalOpen}
        className="modal-user modal-measurement-types"
      >
        <p className="modal-description" style={{ marginBottom: "-20px" }}>
          Upload an XLSX file containing measurement data to proceed
        </p>
        <UploadExcel
          listUploadedExcels={listUploadedExcels}
          setUploadedData={setUploadedData}
        />
        <CalculateExcel
          showNotification={showNotification}
          handleModalOpen={handleModalOpen}
          uploadedData={uploadedData}
        />
      </PortalModal>
    </>
  );
};
