import { FC, useEffect, useRef, useState } from "react";
import { IRecommendedSizeDropdown } from "@models/components/secondary/dropdownmenu";
import { DebounceInput } from "react-debounce-input";
import { setDropdownItems } from "@services/common";
import { RECOMMENDED_SIZE_TITLE } from "@constants/commons";

export const RecommendedSizeDropdown: FC<IRecommendedSizeDropdown> = (
  props
) => {
  const {
    name,
    id,
    error,
    subLabel,
    limit,
    defaultValue,
    dropdownList,
    searchedValue,
    handleSearchedValue,
    handleRecommendedSizesItems,
    disable,
    required,
    clear,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [recommendedSizesItems, setRecommendedSizesItems] = useState(
    defaultValue || []
  );

  const [isOpen, setOpen] = useState(false);
  const [focusedFields, setFocused] = useState({
    [name]: false,
  });

  const focuseField = (fieldName: string) => {
    setFocused({ [fieldName]: true });
  };

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (!recommendedSizesItems.length && defaultValue) {
      setRecommendedSizesItems(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (clear) {
      setRecommendedSizesItems([]);
      setOpen(false);
    }
  }, [clear]);

  useEffect(() => {
    if (focusedFields?.[name]) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [focusedFields]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(e.target as HTMLDivElement)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  const onRecommendedSizesItems = (items: string[]) => {
    setRecommendedSizesItems(items);
    handleRecommendedSizesItems(items);
  };

  const handleDropdownItems = (value: string) => {
    setDropdownItems({
      title: RECOMMENDED_SIZE_TITLE,
      limit,
      recommendedSizes: recommendedSizesItems,
      handleRecommendedSizes: onRecommendedSizesItems,
      value,
      dropdownOpen: () => setOpen(false),
      handleSearchedValue,
    });
  };

  return (
    <>
      <div className={`dropdown form ${isOpen && "isActive"}`}>
        <label htmlFor="dropdown" className="form-label">
          {name} {required && <sup>*</sup>}
        </label>
        <div ref={ref}>
          <div className="dropdown-form">
            <div className="dropdown-input-container">
              {recommendedSizesItems.map((item, index) => (
                <button
                  disabled={disable}
                  className="dropdown-selected"
                  key={`${item}_${index}`}
                  onClick={(e) => e.preventDefault()}
                >
                  {item}
                  <i
                    className="dropdown-selected-icon icon icon-round-close "
                    onClick={() => {
                      const newItems = [
                        ...recommendedSizesItems.filter(
                          (prevItem) => prevItem !== item
                        ),
                      ];
                      setRecommendedSizesItems(newItems);
                      handleRecommendedSizesItems(newItems);
                    }}
                  />
                </button>
              ))}
              <DebounceInput
                id={id}
                name={name}
                type="text"
                placeholder="Choose the size"
                className={`dropdown-input ${
                  !focusedFields[name] && "form-input-error"
                }`}
                onFocus={() => focuseField(name)}
                debounceTimeout={500}
                onChange={(event) => {
                  handleSearchedValue(event.target.value);
                }}
                value={searchedValue}
                disabled={disable}
              />
            </div>
            <button
              className="dropdown-btn"
              onClick={toggleDropdown}
              disabled={disable}
            >
              <i className={`dropdown-icon icon icon-arrow`} />
            </button>
          </div>
          <ul className="dropdown-list">
            {!!dropdownList?.length ? (
              dropdownList.map((value, index) => (
                <li
                  className={`dropdown-item ${
                    value ===
                      recommendedSizesItems.find((item) => item === value) &&
                    "isActive"
                  }`}
                  onClick={() => handleDropdownItems(value)}
                  key={`${value}_${index}`}
                >
                  {value}
                </li>
              ))
            ) : (
              <li className="dropdown-item">
                <span className="dropdown-no-result">No result found.</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      {subLabel && error && (
        <label
          htmlFor={id}
          className={`form-sublabel ${error && "form-input-error"}`}
        >
          <>{subLabel}</>
        </label>
      )}
    </>
  );
};
