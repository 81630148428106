import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Input } from "@components/Input";
import { PortalModal } from "@components/PortalModal";
import {
  IManageMeasurementModal,
  IMeasurementParticipationSizes,
} from "@models/components/secondary/measurementsmodal";
import { GenderSegment } from "@components/GenderSegment";
import { GenderValue } from "@models/components/secondary/gendersegment";
import { FEMALE_USER_GENDER, genders } from "@constants/commons";
import { Button } from "@components/Button";
import { sortGenderMeasurementParameters } from "@services/common";
import { FormProvider, useForm } from "react-hook-form";
import { eventInfoSlice } from "@store/reducers/EventSlice";
import {
  ADD_MEASUREMENT_TITLE,
  EDIT_MEASUREMENT_TITLE,
  NO_GENDER_MEASUREMENTS,
} from "@constants/measurements";
import {
  GenderMeasurementParameter,
  IMeasurementParameter,
} from "@models/common/measurements";
import { getFormattedError } from "@services/http";
import { IAddMeasurementsSendData, ProductType } from "@models/common/events";
import { MeasurementInput } from "./MeasurementInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { REQUIRED_FIELD } from "@constants/validation";
import classNames from "classnames";
import {
  RecommendedSizesProcessing,
  DropdownWithMessages,
} from "./RecommendedSizes";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import {
  addMeasurementsData,
  addMeasurementsSelectedSizes,
} from "@store/selectors/event";
import {
  getMeasurementParticipationSizes,
  saveSelectedAndRecommendedSizes,
} from "@api/events";
import { useParams } from "react-router-dom";
import { MiniLoader } from "@components/Loader/MiniLoader";

interface EnhancedProductType extends ProductType {
  items: (GenderMeasurementParameter & { isDuplicate: boolean })[];
}

export const ManageMeasurementsModal: FC<IManageMeasurementModal> = ({
  handleModalOpen,
  eventMeasurementsInfo,
  userMeasurementsInfo,
  handleUser,
  title,
  userInfo,
  showWhoMeasured = true,
  eventData,
  activeEndUserMeasurement,
}) => {
  const measurementsDataStore = useAppSelector(addMeasurementsData);
  const measurementsSelectedSizes = useAppSelector(
    addMeasurementsSelectedSizes
  );
  const isEditMode = title === EDIT_MEASUREMENT_TITLE;
  const dispatch = useAppDispatch();
  const { eventId } = useParams();
  const [participationSizes, setParticipationSizes] = useState<
    IMeasurementParticipationSizes[]
  >([]);

  const initialValues = useMemo(() => {
    const whoMeasured = isEditMode
      ? userMeasurementsInfo?.whoMeasured ?? ""
      : eventMeasurementsInfo.whoMeasured;
    const measurementData = userMeasurementsInfo?.measurementData.reduce(
      (result, { measurementParam, value }) => ({
        ...result,
        [measurementParam.measurementParamId]: value,
      }),
      {}
    );

    return {
      whoMeasured,
      measurementData,
    };
  }, [isEditMode, userMeasurementsInfo, eventMeasurementsInfo]);

  const initialGender = userMeasurementsInfo
    ? userMeasurementsInfo?.gender === FEMALE_USER_GENDER
      ? genders[1]
      : genders[0]
    : userInfo?.gender === FEMALE_USER_GENDER
    ? genders[1]
    : genders[0];

  const [selectedGender, setSelectedGender] =
    useState<GenderValue>(initialGender);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [sizeChartUrl, setSizeChartUrl] = useState<string | undefined>();
  const isMaleSelected = selectedGender.value === "Male";
  const [imageTooHeight, setImageTooHeight] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(eventInfoSlice.actions.clearAddMeasurementsDataAndErrors());
    };
  }, []);

  useEffect(() => {
    if (isEditMode) {
      (async function asyncWrapper() {
        try {
          const measurementParticipationSizes =
            await getMeasurementParticipationSizes(
              dispatch,
              eventData?.eventId as string,
              userInfo?.userId as string
            );
          setParticipationSizes(measurementParticipationSizes.sizes);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [isEditMode, eventData, userInfo]);

  const onSubmit = async ({
    whoMeasured,
    measurementData: rawMeasurementData = {},
  }: {
    whoMeasured?: string;
    measurementData?: Record<string, string>;
  }) => {
    const measurementData = Object.entries(rawMeasurementData).map(
      ([measurementParamId, value = ""]) => ({
        measurementParamId,
        value,
      })
    );

    try {
      if (
        (title === ADD_MEASUREMENT_TITLE || title === EDIT_MEASUREMENT_TITLE) &&
        eventId
      ) {
        const reducedSelectedSizes = measurementsSelectedSizes.map(
          ({ gender, selectedSize, productTypeId }) => ({
            productTypeId,
            gender,
            sizes: Object.fromEntries(
              selectedSize.map(({ sizeName, size }) => [sizeName, size])
            ),
          })
        );
        const selectedAndRecommendedSizes: IAddMeasurementsSendData[] =
          measurementsDataStore.map((item) => {
            let recommended: [string, string][] = [];
            let comments: [string, string][] = [];
            Object.entries(item.data).forEach((item) => {
              if (item[0].toLowerCase().includes("comment")) {
                comments.push(item);
              } else {
                recommended.push(item);
              }
            });

            return {
              productTypeId: item.productTypeId,
              gender: item.gender,
              recommendedSize: Object.fromEntries(recommended),
              selectedSize:
                reducedSelectedSizes.find(
                  (selectedItem) =>
                    selectedItem.gender === item.gender &&
                    selectedItem.productTypeId === item.productTypeId
                )?.sizes || {},
              comment: Object.fromEntries(comments),
            };
          });

        await saveSelectedAndRecommendedSizes(
          dispatch,
          eventData?.eventId as string,
          activeEndUserMeasurement as string,
          selectedAndRecommendedSizes
        );
      }
      await handleUser({
        gender: isMaleSelected ? "MALE" : "FEMALE",
        measurementData,
        whoMeasured,
      });
      handleModalOpen(userInfo?.userId || "");
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  const measurementParams = useMemo(() => {
    return eventMeasurementsInfo?.measurementData
      ?.reduce((result, item) => {
        const measurementParamIdDuplicates = result.reduce(
          (duplicates, resultItem) => {
            const measurementParamIds = resultItem.items.map(
              ({ measurementParam }) => measurementParam.measurementParamId
            );

            return [...duplicates, ...measurementParamIds];
          },
          [] as string[]
        );

        const genderToMeasurementParams = isMaleSelected
          ? item.genderToMeasurementParams?.MALE
          : item.genderToMeasurementParams?.FEMALE;

        const itemsWithMarkedDuplicates = genderToMeasurementParams?.map(
          (genderToMeasurementParam) => ({
            ...genderToMeasurementParam,
            isDuplicate: measurementParamIdDuplicates.includes(
              genderToMeasurementParam.measurementParam.measurementParamId
            ),
          })
        );

        const processedItem = {
          ...item,
          items: sortGenderMeasurementParameters(itemsWithMarkedDuplicates),
        };

        return [...result, processedItem];
      }, [] as EnhancedProductType[])
      .filter(({ items }) => items.length);
  }, [isMaleSelected, eventMeasurementsInfo]);

  const measurementParamIds = useMemo(() => {
    return measurementParams
      .map(({ items }) =>
        items.map(({ measurementParam }) => measurementParam.measurementParamId)
      )
      .flat();
  }, [measurementParams]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        ...(showWhoMeasured
          ? { whoMeasured: yup.string().required(REQUIRED_FIELD) }
          : {}),
        measurementData: yup.object(
          measurementParamIds?.reduce(
            (result, measurementParamId) => ({
              ...result,
              [measurementParamId]: yup.string().required(REQUIRED_FIELD),
            }),
            {}
          )
        ),
      }),
    [measurementParamIds, showWhoMeasured]
  );

  const formMethods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
  });

  const {
    formState: { isValid },
  } = formMethods;

  const measurementParamTips = useMemo(() => {
    const allGenderMeasurementParamItems = measurementParams
      ?.map(({ items }) => items)
      .flat();

    const uniqueItems = _.uniqBy(
      allGenderMeasurementParamItems,
      ({ measurementParam }) => measurementParam.measurementParamId
    );

    return sortGenderMeasurementParameters(uniqueItems);
  }, [measurementParams]);

  const initialSelectedMeasurement =
    measurementParamTips?.[0]?.measurementParam || null;

  const [selectedMeasurement, setSelectedMeasurement] =
    useState<IMeasurementParameter | null>(initialSelectedMeasurement);

  useEffect(() => {
    if (measurementParamTips && measurementParamTips.length > 0) {
      setSelectedMeasurement(measurementParamTips[0].measurementParam);
    }
  }, [measurementParamTips]);

  const isSizeChartButtonDisabled = (
    maleSizeChartUrl: string | undefined,
    femaleSizeChartUrl: string | undefined
  ) => {
    return (
      (selectedGender.value === "Male" && !maleSizeChartUrl) ||
      (selectedGender.value === "Female" && !femaleSizeChartUrl)
    );
  };

  const openSizeChartWindow = (
    maleSizeChartUrl: string | undefined,
    femaleSizeChartUrl: string | undefined
  ) => {
    const url =
      selectedGender.value === "Male" ? maleSizeChartUrl : femaleSizeChartUrl;
    setSizeChartUrl(url);
    setShowSizeChart(true);
  };

  const closeSizeChartWindow = () => {
    setSizeChartUrl(undefined);
    setShowSizeChart(false);
    setIsImageLoaded(false);
  };

  const handleImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const img = event.target as HTMLImageElement;
    setImageTooHeight(img.naturalHeight > 600);
    setIsImageLoaded(true);
  };

  return (
    <>
      {
        <PortalModal
          title={"Size chart"}
          isOpen={true}
          onClose={closeSizeChartWindow}
          className={classNames("modal-size-chart", {
            "modal-view-size-chart--portrait": imageTooHeight,
            "modal-hide": !showSizeChart,
          })}
        >
          <div
            className={classNames("modal-view-size-chart", {
              "modal-view-size-chart--loaded": isImageLoaded,
            })}
          >
            <img src={sizeChartUrl} alt="Size chart" onLoad={handleImageLoad} />
          </div>
          {!isImageLoaded && (
            <div className={"modal-view-size-chart__loader"}>
              <MiniLoader time={100} />
            </div>
          )}
        </PortalModal>
      }
      <PortalModal
        title={title}
        isOpen={true}
        onClose={() => handleModalOpen(userInfo?.userId || "")}
        className={!showSizeChart ? "" : "modal-hide"}
      >
        <div className="modal-events-actions">
          <div className="modal-events-actions-inner">
            <FormProvider key={selectedGender.value} {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                {showWhoMeasured ? (
                  <div className="form modal-form-head form-start-label">
                    <Input
                      label="Who measured*"
                      name="whoMeasured"
                      placeholder="SR name by default"
                    />
                  </div>
                ) : (
                  <Input name="whoMeasured" type="hidden" />
                )}
                <div className="form modal-form modal-gender form-start-label">
                  <span className="form-label isDisabled">Gender</span>
                  <GenderSegment
                    isDisabled={false}
                    handleGender={setSelectedGender}
                    selectedGender={selectedGender}
                    customGenders={genders.slice(0, 2)}
                  />
                </div>
                <div className="groups">
                  {measurementParams?.length ? (
                    measurementParams.map(
                      ({
                        items,
                        name,
                        recommendedSizeTemplate,
                        maleSizeChartUrl,
                        femaleSizeChartUrl,
                        productTypeId,
                      }) => {
                        const isSizeChartDisabled: boolean =
                          isSizeChartButtonDisabled(
                            maleSizeChartUrl,
                            femaleSizeChartUrl
                          );
                        return (
                          <div
                            key={name}
                            className="modal-cols form-start-label modal-cols--group"
                          >
                            <div
                              className={`form-group-header${
                                recommendedSizeTemplate &&
                                (title === ADD_MEASUREMENT_TITLE ||
                                  title === EDIT_MEASUREMENT_TITLE)
                                  ? " form-group-header-add"
                                  : ""
                              }`}
                            >
                              <div className="form-group-label">{name}</div>
                              <button
                                type="button"
                                disabled={isSizeChartDisabled}
                                className={classNames("size-chart-button", {
                                  "size-chart-button--disabled":
                                    isSizeChartDisabled,
                                })}
                                onClick={() =>
                                  openSizeChartWindow(
                                    maleSizeChartUrl,
                                    femaleSizeChartUrl
                                  )
                                }
                              >
                                View Size Chart
                                {isSizeChartDisabled && (
                                  <span className="size-chart-button__tooltip">
                                    No image to display
                                  </span>
                                )}
                              </button>
                              {recommendedSizeTemplate &&
                                (title === ADD_MEASUREMENT_TITLE ||
                                  title === EDIT_MEASUREMENT_TITLE) && (
                                  <>
                                    <RecommendedSizesProcessing
                                      gender={
                                        isMaleSelected ? "MALE" : "FEMALE"
                                      }
                                      productTypeId={productTypeId}
                                      templateId={
                                        recommendedSizeTemplate.templateId
                                      }
                                      measurementParams={items.map(
                                        ({ measurementParam }) => ({
                                          measurementParamId:
                                            measurementParam.measurementParamId,
                                          name: measurementParam.name,
                                        })
                                      )}
                                    />
                                    <DropdownWithMessages
                                      gender={
                                        isMaleSelected ? "MALE" : "FEMALE"
                                      }
                                      productTypeId={productTypeId}
                                      templateId={
                                        recommendedSizeTemplate.templateId
                                      }
                                      participationSizes={participationSizes}
                                    />
                                  </>
                                )}
                            </div>
                            {items.map(({ measurementParam, isDuplicate }) => {
                              const inputName = `measurementData.${measurementParam.measurementParamId}`;

                              return (
                                <MeasurementInput
                                  key={inputName}
                                  label={`${measurementParam.name}*`}
                                  name={inputName}
                                  isDuplicate={isDuplicate}
                                />
                              );
                            })}
                          </div>
                        );
                      }
                    )
                  ) : (
                    <span className="modal-no-measurements-text">
                      {NO_GENDER_MEASUREMENTS}
                    </span>
                  )}
                </div>
                <div className="modal-footer">
                  <Button
                    text="Cancel"
                    className="btn btn-primary"
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                      e.preventDefault();
                      handleModalOpen(userInfo?.userId || "");
                    }}
                  />
                  <Button
                    text="Save"
                    className="btn btn-main"
                    type="submit"
                    disable={!isValid}
                  />
                </div>
              </form>
            </FormProvider>
          </div>
          <div className="modal-inner modal-tips" style={{ padding: 20 }}>
            <div className="modal-tips-wrapper">
              <span className="modal-tips-title">Tips how to measure</span>
              <ul className="modal-tips-list">
                {measurementParamTips?.map((param) => (
                  <li
                    className={`modal-tips-item ${
                      selectedMeasurement?.measurementParamId ===
                        param.measurementParam.measurementParamId && "isActive"
                    }`}
                    key={param.measurementParam.measurementParamId}
                    onClick={() => {
                      selectedMeasurement?.measurementParamId !==
                        param.measurementParam.measurementParamId &&
                        setSelectedMeasurement(param.measurementParam);
                    }}
                  >
                    {param.measurementParam.name}
                  </li>
                ))}
              </ul>
            </div>
            {selectedMeasurement?.imageUrl ? (
              <div className="modal-image">
                <img
                  className="modal-image-tips"
                  src={selectedMeasurement.imageUrl}
                  alt={selectedMeasurement.name}
                />
              </div>
            ) : (
              <div
                className="modal-image modal-no-image"
                style={{ height: 244, width: 244 }}
              >
                <i className="icon icon-no-photo"></i>
                <span className="modal-image-text">No tips yet</span>
              </div>
            )}
          </div>
        </div>
      </PortalModal>
    </>
  );
};
