import {
  IProductTypes,
  IUploadedExcels,
  ProductType,
} from "@models/common/events";
import {
  IUploadExcelParameter,
  RequestGenderMeasurementParameter,
} from "@models/common/measurements";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";
import { pickBy } from "lodash";
import { FileContent } from "use-file-picker";
import { dataURLtoFile } from "@services/utils/file-utils";
import { CalculateTabFields } from "@models/components/secondary/uploadedexcelfilesmodal";

export const getProductTypesBySearchKey = async (
  dispatch: AppDispatch,
  searchKey: string,
  size: number = 200
) =>
  await http.get<IProductTypes>(
    !!searchKey
      ? `/api/v1/product/type?searchKey=${searchKey}${
          size ? `&size=${size}` : ""
        }`
      : `/api/v1/product/type${size ? `?size=${size}` : ""}`,
    dispatch
  );

export const getProductTypesByIds = async (
  dispatch: AppDispatch,
  productTypeIds: string[]
) =>
  await http.post<ProductType[], string[]>(
    "/api/v1/product/type/by-product-type-ids",
    productTypeIds,
    dispatch
  );

export const listOfFilteredProductTypes = async (
  dispatch: AppDispatch,
  page: number,
  size: number,
  sort: string,
  searchKey?: string
) =>
  await http.post<IProductTypes, { searchKey: string | null }>(
    `/api/v1/product/type/filter?page=${page}&size=${size}&sort=${sort}`,
    { searchKey: searchKey || null },
    dispatch
  );

export const updateProductType = async (
  dispatch: AppDispatch,
  productTypeData: {
    productTypeId: string;
    name: string;
    genderToMeasurementParamRefs: {
      FEMALE?: RequestGenderMeasurementParameter[];
      MALE?: RequestGenderMeasurementParameter[];
    };
  }
) => {
  const genderToMeasurementParamRefs = pickBy({
    MALE: !!productTypeData.genderToMeasurementParamRefs?.MALE?.length
      ? productTypeData.genderToMeasurementParamRefs.MALE
      : null,
    FEMALE: !!productTypeData.genderToMeasurementParamRefs?.FEMALE?.length
      ? productTypeData.genderToMeasurementParamRefs.FEMALE
      : null,
  });

  return await http.post<
    ProductType,
    {
      name: string;
      genderToMeasurementParamRefs: {
        MALE?: RequestGenderMeasurementParameter[];
        FEMALE?: RequestGenderMeasurementParameter[];
      };
    }
  >(
    `/api/v1/product/type/${productTypeData.productTypeId}`,
    {
      name: productTypeData.name,
      genderToMeasurementParamRefs,
    },
    dispatch
  );
};

export const createProductType = async (
  dispatch: AppDispatch,
  productTypeData: {
    productTypeId: string;
    name: string;
    genderToMeasurementParamRefs: {
      FEMALE?: RequestGenderMeasurementParameter[];
      MALE?: RequestGenderMeasurementParameter[];
    };
  }
) => {
  const genderToMeasurementParamRefs = pickBy({
    MALE: !!productTypeData.genderToMeasurementParamRefs?.MALE?.length
      ? productTypeData.genderToMeasurementParamRefs.MALE
      : null,
    FEMALE: !!productTypeData.genderToMeasurementParamRefs?.FEMALE?.length
      ? productTypeData.genderToMeasurementParamRefs.FEMALE
      : null,
  });

  return await http.post<
    ProductType,
    {
      name: string;
      genderToMeasurementParamRefs: {
        MALE?: RequestGenderMeasurementParameter[];
        FEMALE?: RequestGenderMeasurementParameter[];
      };
    }
  >(
    "/api/v1/product/type",
    {
      name: productTypeData.name,
      genderToMeasurementParamRefs,
    },
    dispatch
  );
};

export const getProductTypeById = async (
  dispatch: AppDispatch,
  productTypeId: string
) =>
  await http.get<ProductType>(
    `/api/v1/product/type/${productTypeId}`,
    dispatch
  );

export const uploadExcelParam = async (
  dispatch: AppDispatch,
  formData: FormData
) => {
  return await http.post<IUploadExcelParameter, FormData>(
    "/api/v1/product/recommended-size/retrieve-columns",
    formData,
    dispatch,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getUploadedExcels = async (
  dispatch: AppDispatch,
  size: number = 100,
  page: number = 0
) =>
  await http.get<IUploadedExcels>(
    `/api/v1/product/recommended-size/template?page=${page}&size=${size}`,
    dispatch
  );

export const deleteUploadedExcel = async (
  dispatch: AppDispatch,
  templateId: string
) =>
  await http.delete(
    `/api/v1/product/recommended-size/${templateId}`,
    null,
    dispatch
  );

export const uploadSizeChart = async (
  dispatch: AppDispatch,
  productTypeId: string,
  image: FileContent,
  gender: "male" | "female"
) => {
  const formData = new FormData();
  formData.append("sizeChartFile", dataURLtoFile(image.content, image.name));
  formData.append("gender", gender.toUpperCase());
  return await http.post<ProductType, FormData>(
    `/api/v1/product/type/${productTypeId}/size-chart`,
    formData,
    dispatch,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const deleteSizeChart = async (
  dispatch: AppDispatch,
  productTypeId: string,
  gender: "male" | "female"
) => {
  return await http.delete<ProductType, null>(
    `/api/v1/product/type/${productTypeId}/size-chart/${gender.toUpperCase()}`,
    null,
    dispatch
  );
};

export const calculateRecommendedSize = async (
  dispatch: AppDispatch,
  templateId: string,
  formData: CalculateTabFields
) =>
  await http.post<CalculateTabFields, CalculateTabFields>(
    `/api/v1/product/recommended-size/calculate/${templateId}`,
    formData,
    dispatch
  );

export const saveUploadedExcel = async (
  dispatch: AppDispatch,
  templateId: string,
  productTypeId: string
) =>
  await http.put(
    `/api/v1/product/recommended-size/bind/${templateId}`,
    productTypeId,
    dispatch
  );
