import { createSlice } from "@reduxjs/toolkit";
import { TypesState } from "@models/common/measurements";

const initialState: TypesState = {
  confirmation: false,
};

export const typesSlice = createSlice({
  name: "types",
  initialState,
  reducers: {
    setCleanConfirmation: (state) => {
      state.confirmation = true;
    },
    cleanConfirmation: (state) => {
      state.confirmation = false;
    },
  },
});

export default typesSlice.reducer;
