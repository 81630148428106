import React, { useCallback, useEffect, useState } from "react";
import { FileContent, useFilePicker } from "use-file-picker";

import { measurementsImageExtensions } from "@constants/commons";

type ImagePickerProps = {
  onChange: (image: FileContent | null) => void;
  allowedExtensions?: string[];
  maxSize?: number;
  preloadedImage?: string;
  showDeleteButton?: boolean;
};

export const ImagePicker: React.FC<ImagePickerProps> = ({
  onChange,
  allowedExtensions = measurementsImageExtensions,
  maxSize = 5,
  preloadedImage,
  showDeleteButton = true,
}) => {
  const [openFileSelector, { filesContent, errors, clear }] = useFilePicker({
    accept: allowedExtensions,
    limitFilesConfig: { max: 1 },
    readAs: "DataURL",
    maxFileSize: maxSize,
  });

  const [fileContentError, setFileContentError] = useState(false);
  const [displayImage, setDisplayImage] = useState<string | null>(
    preloadedImage || null
  );

  const stableOnChange = useCallback(onChange, []);

  useEffect(() => {
    if (filesContent?.[0]?.name) {
      const fileExtension = filesContent[0].name
        .split(".")
        .pop()
        ?.toLowerCase();
      const isValidExtension = allowedExtensions.some(
        (ext) => ext.replace(".", "").toLowerCase() === fileExtension
      );
      setFileContentError(!isValidExtension);
      const file = isValidExtension ? filesContent[0] : null;
      stableOnChange(file);
      setDisplayImage(file?.content || preloadedImage || null);
    }
  }, [filesContent, allowedExtensions, stableOnChange, preloadedImage]);

  const handleRemoveImage = useCallback(() => {
    clear();
    stableOnChange(null);
    setDisplayImage(null);
  }, []);

  return (
    <>
      <div className="image-selector">
        <span className="image-selector__title">Size chart</span>

        {fileContentError || !displayImage ? (
          <div className="image-selector__picker" onClick={openFileSelector}>
            <i className="icon icon-add-photo"></i>

            <span className="image-selector__text-placeholder">
              Click to add photo
            </span>
          </div>
        ) : (
          <>
            <div
              className="image-selector__image"
              onClick={() => !showDeleteButton && handleRemoveImage()}
            >
              <img
                src={filesContent?.[0]?.content || preloadedImage || undefined}
                alt={filesContent?.[0]?.name}
              />
            </div>
            {showDeleteButton && (
              <div
                className="image-selector__delete-action"
                onClick={handleRemoveImage}
              >
                <i className="icon icon-delete"></i>
              </div>
            )}
          </>
        )}
        {errors?.[0]?.fileSizeToolarge && (
          <div className="image-selector__error-message">
            Maximum size: {maxSize} MB
          </div>
        )}
        {fileContentError && (
          <div className="image-selector__error-message">Invalid format</div>
        )}
      </div>
    </>
  );
};
