import { FC } from "react";
import { addMeasurementsErrors } from "@store/selectors/event";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { IRecommendedSizesErrorMessages } from "@models/components/secondary/measurementsmodal";

export const RecommendedSizesErrorMessages: FC<
  IRecommendedSizesErrorMessages
> = ({ productTypeId, gender }) => {
  const measurementsErrors = useAppSelector(addMeasurementsErrors);
  const foundedMessageData = measurementsErrors.find(
    (errMsg) =>
      errMsg.productTypeId === productTypeId && errMsg.gender === gender
  );

  return foundedMessageData ? (
    <p className="form-sublabel form-error" style={{ marginTop: "-12px" }}>
      {foundedMessageData.message}
    </p>
  ) : null;
};
