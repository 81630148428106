import { FC } from "react";
import { addMeasurementsData } from "@store/selectors/event";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { IRecommendedSizesDataMessages } from "@models/components/secondary/measurementsmodal";

export const RecommendedSizesCommentMessages: FC<
  Omit<IRecommendedSizesDataMessages, "selectedSize" | "fieldName">
> = ({ productTypeId, gender }) => {
  const measurementsData = useAppSelector(addMeasurementsData);

  const foundedMessageData = measurementsData.find(
    (dataMsg) =>
      dataMsg.productTypeId === productTypeId && dataMsg.gender === gender
  );
  const correctDataField = foundedMessageData
    ? Object.entries(foundedMessageData.data)
        .map(([fieldName, value = ""]) => ({ fieldName, value }))
        .filter((item) =>
          item.fieldName.trim().toLowerCase().includes("comment")
        )
    : null;

  return (
    <>
      {correctDataField?.map((field, index) => (
        <p
          key={`${field.value}_${index}`}
          className="form-sublabel form-success-msg"
          style={{ marginTop: "6px" }}
        >
          {field.value}
        </p>
      ))}
    </>
  );
};
