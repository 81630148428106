import { FC } from "react";
import { addMeasurementsData } from "@store/selectors/event";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { IRecommendedSizesDataMessages } from "@models/components/secondary/measurementsmodal";
import {
  RECOMMENDED_SIZE_MESSAGE,
  NOT_RECOMMENDED_SIZE_TITLE,
} from "@constants/commons";

export const RecommendedSizesDataMessages: FC<
  IRecommendedSizesDataMessages
> = ({ productTypeId, gender, fieldName, selectedSize }) => {
  const measurementsData = useAppSelector(addMeasurementsData);

  // logic for main message
  const foundedMessageData = measurementsData.find(
    (dataMsg) =>
      dataMsg.productTypeId === productTypeId && dataMsg.gender === gender
  );
  const correctDataField = foundedMessageData
    ? Object.entries(foundedMessageData.data).map(
        ([fieldName, value = ""]) => ({ fieldName, value })
      )
    : null;
  const correctField = correctDataField?.find((item) =>
    item.fieldName.includes(fieldName)
  );

  // logic for warning massage
  const foundedNeededSize = selectedSize.find(
    (item) => item.sizeName === fieldName
  );
  const isWarning =
    foundedNeededSize &&
    correctField &&
    !(correctField.value === foundedNeededSize.size);

  return correctField ? (
    <>
      <p
        className="form-sublabel form-success-msg"
        style={{ marginTop: "6px" }}
      >
        {RECOMMENDED_SIZE_MESSAGE}
        <span className="form-success-msg-bold">{correctField.value}</span>
      </p>
      {isWarning && (
        <p
          className="form-sublabel form-success-msg-warning"
          style={{ marginTop: "4px" }}
        >
          {NOT_RECOMMENDED_SIZE_TITLE}
        </p>
      )}
    </>
  ) : null;
};
