export const ADD_MEASUREMENT_TITLE = "Add measurements";
export const EDIT_MEASUREMENT_TITLE = "Edit measurements";
export const UPDATE_MEASUREMENT_TITLE = "Update measurements";

export const NO_GENDER_MEASUREMENTS =
  "There are no measurements for the selected gender";

export const DELETE_FILE_TITLE = "Delete file";
export const DELETE_FILE_BODY = (name: string) =>
  `Are you sure you want to delete "${name}" file?`;

export const CLOSE_UPLOAD_FILE_TITLE = "Cancel upload";
export const CLOSE_UPLOAD_FILE_BODY =
  "Are you sure you want to cancel upload excel?";
