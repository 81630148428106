import React, { FC, useEffect, useRef, useState } from "react";
import { RecommendedSizeDropdown } from "@components/Dropdown/RecommendedSizeDropdown";
import {
  RecommendedSizesDataMessages,
  RecommendedSizesErrorMessages,
  RecommendedSizesCommentMessages,
} from "./components";
import { getCurrentSizes } from "@api/events";
import { getFormattedError } from "@services/http";
import {
  IDropdownWithMessages,
  IRecommendedSizes,
  ISelectedRecommendedSizes,
} from "@models/components/secondary/measurementsmodal";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { eventInfoSlice } from "@store/reducers/EventSlice";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { addMeasurementsData } from "@store/selectors/event";

export const DropdownWithMessages: FC<IDropdownWithMessages> = ({
  gender,
  productTypeId,
  templateId,
  participationSizes,
}) => {
  const dispatch = useAppDispatch();
  const sizesRef = useRef<IRecommendedSizes[] | null>(null);
  const [selectedSize, setSelectedSize] = useState<ISelectedRecommendedSizes[]>(
    []
  );
  const [listSizes, setListSizes] = useState<IRecommendedSizes[] | null>(null);
  const [searchedSize, setSearchedSize] = useState<{
    searchValue: string;
    sizesName: string | null;
  }>({ searchValue: "", sizesName: null });
  const flagRef = useRef<boolean>(true);
  const measurementsData = useAppSelector(addMeasurementsData);

  useEffect(() => {
    if (flagRef.current) {
      const foundedMessageData = measurementsData.find(
        (dataMsg) =>
          dataMsg.productTypeId === productTypeId && dataMsg.gender === gender
      );

      if (participationSizes.length !== 0) {
        const foundedSizes = participationSizes.find(
          (item) =>
            item.productTypeId === productTypeId && item.gender === gender
        );
        if (foundedSizes) {
          setSelectedSize(
            Object.entries(foundedSizes.selectedSize).map(([key, val]) => ({
              sizeName: key,
              size: val,
            }))
          );
        }
        flagRef.current = false;
      } else if (
        !participationSizes.length &&
        flagRef.current &&
        !selectedSize.length &&
        measurementsData.length &&
        foundedMessageData
      ) {
        const correctDataField = Object.entries(foundedMessageData.data)
          .filter(([key]) => key.toLowerCase().includes("recommended"))
          .map(([key, val]) => ({
            sizeName: key.split("Recommended").join(" ").trim(),
            size: val,
          }));
        setSelectedSize(correctDataField);
        flagRef.current = false;
      }
    }
  }, [participationSizes, flagRef.current, selectedSize, measurementsData]);

  useEffect(() => {
    dispatch(
      eventInfoSlice.actions.setSelectedSizesData({
        productTypeId,
        gender,
        selectedSize,
      })
    );
  }, [selectedSize]);

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        const sizesData = await getCurrentSizes(dispatch, templateId);
        const sizesList = Object.entries(sizesData).map(
          ([sizeName, sizes]) => ({
            sizeName,
            sizes,
          })
        );
        sizesRef.current = sizesList;
        setListSizes(sizesList);
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, []);

  useEffect(() => {
    if (sizesRef.current) {
      if (searchedSize.searchValue) {
        const searchVal = searchedSize.searchValue.trim().toLowerCase();

        const suitableValues = sizesRef.current.map((sizeItem) => {
          if (sizeItem.sizeName === searchedSize.sizesName) {
            return {
              sizeName: sizeItem.sizeName,
              sizes: sizeItem.sizes.filter((sizeIt) =>
                sizeIt.trim().toLowerCase().includes(searchVal)
              ),
            };
          }
          return sizeItem;
        });

        setListSizes(suitableValues);
      } else {
        setListSizes(sizesRef.current);
      }
    }
  }, [searchedSize, sizesRef]);

  const handleRecommendedSize = (data: IRecommendedSizes) => {
    setSelectedSize((prevState) => {
      if (prevState.find((item) => item.sizeName === data.sizeName)) {
        if (data.sizes.length) {
          return prevState.map((sizeItem) => {
            if (sizeItem.sizeName === data.sizeName) {
              return { ...sizeItem, size: data.sizes[0] };
            }
            return sizeItem;
          });
        } else {
          return prevState.filter(
            (sizeItem) => sizeItem.sizeName !== data.sizeName
          );
        }
      }
      return [...prevState, { ...data, size: data.sizes[0] }];
    });
  };

  return listSizes ? (
    <>
      {listSizes.map((size, index) => {
        const foundedDefaultValue = selectedSize.find(
          (item) => item.sizeName === size.sizeName
        );
        const defaultValue = foundedDefaultValue
          ? [foundedDefaultValue.size]
          : [];

        return (
          <div className="recommended-size" key={`${size.sizeName}_${index}`}>
            <RecommendedSizeDropdown
              defaultValue={defaultValue}
              name={size.sizeName}
              id="dropdown"
              limit={1}
              dropdownList={size.sizes}
              searchedValue={searchedSize.searchValue}
              handleRecommendedSizesItems={(value: string[]) => {
                handleRecommendedSize({
                  sizeName: size.sizeName,
                  sizes: value,
                });
              }}
              handleSearchedValue={(val: string) =>
                setSearchedSize({
                  searchValue: val,
                  sizesName: size.sizeName,
                })
              }
            />
            <RecommendedSizesDataMessages
              productTypeId={productTypeId}
              gender={gender}
              fieldName={size.sizeName}
              selectedSize={selectedSize}
            />
            {index === listSizes.length - 1 && (
              <RecommendedSizesCommentMessages
                productTypeId={productTypeId}
                gender={gender}
              />
            )}
          </div>
        );
      })}
      <RecommendedSizesErrorMessages
        productTypeId={productTypeId}
        gender={gender}
      />
    </>
  ) : null;
};
