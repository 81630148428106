import React, { FC, useEffect, useMemo, useState } from "react";
import { PortalModal } from "@components/PortalModal";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { MiniLoader } from "@components/Loader/MiniLoader";
import {
  IUploadedExcel,
  IViewUploadedExcelsModal,
} from "@models/components/secondary/uploadedexcelfilesmodal";
import { deleteUploadedExcel, getUploadedExcels } from "@api/types";
import {
  FILE_DELETED_NOTIFICATION,
  SMALL_ITEMS_COUNT_WITHOUT_PAGINATION,
  FILE_DELETED_ERROR_NOTIFICATION,
  ANY_FILES,
} from "@constants/commons";
import { getFormattedError } from "@services/http";
import { DELETE_FILE_TITLE, DELETE_FILE_BODY } from "@constants/measurements";
import { AlertModal } from "@components/AlertModal";
import ReactDOM from "react-dom";

export const ViewUploadedExcelsModal: FC<IViewUploadedExcelsModal> = ({
  title,
  handleModalOpen,
}) => {
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);
  const [listUploadedExcels, setListUploadedExcels] = useState<
    IUploadedExcel[] | null
  >(null);
  const [deletedTemplateId, setDeletedTemplateId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useAppDispatch();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const listStyles = useMemo(() => {
    if (listUploadedExcels && listUploadedExcels.length === 0) {
      return {
        display: "flex",
        justifyContent: "center",
      };
    }
    return {};
  }, [listUploadedExcels]);

  const deleteFileName = useMemo(() => {
    if (deletedTemplateId) {
      const file = listUploadedExcels?.find(
        (item) => item.templateId === deletedTemplateId
      );
      return file?.templateName || "";
    }
    return "";
  }, [deletedTemplateId, listUploadedExcels]);

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        const uploadedExcels = await getUploadedExcels(
          dispatch,
          SMALL_ITEMS_COUNT_WITHOUT_PAGINATION,
          0
        );
        setListUploadedExcels(uploadedExcels.content);
      } catch (error) {
        handleModalOpen();
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, []);

  const handleDelete = async (id: string) => {
    setDeleteLoading(true);
    try {
      await deleteUploadedExcel(dispatch, id);
      showNotification(FILE_DELETED_NOTIFICATION, "success");
      setListUploadedExcels((prevState) =>
        (prevState as IUploadedExcel[]).filter((item) => item.templateId !== id)
      );
    } catch (error) {
      showNotification(FILE_DELETED_ERROR_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleOpenDeleteFile = (id: string) => {
    setDeletedTemplateId(id);
    setDeleteWindowOpen(true);
  };

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {deleteWindowOpen &&
        ReactDOM.createPortal(
          <AlertModal
            type="delete"
            title={DELETE_FILE_TITLE}
            body={DELETE_FILE_BODY(deleteFileName)}
            handleOpen={() => setDeleteWindowOpen(false)}
            handleConfirm={handleDelete}
            id={deletedTemplateId}
          />,
          document.body
        )}
      <PortalModal
        title={title}
        isOpen={true}
        onClose={handleModalOpen}
        className="modal-user modal-measurement-types"
      >
        <div className="modal-wrapper jc-center">
          {!listUploadedExcels ? (
            <MiniLoader style={{ marginLeft: "-30px" }} />
          ) : (
            <ul className="modal-excel-files-list" style={listStyles}>
              {listUploadedExcels.length ? (
                listUploadedExcels.map((item) => (
                  <li
                    key={item.templateId}
                    className="modal-excel-files-list-item"
                  >
                    {item.templateName}
                    <button
                      disabled={deleteLoading}
                      className="btn-clean"
                      onClick={() => handleOpenDeleteFile(item.templateId)}
                    >
                      {deleteLoading &&
                      deletedTemplateId === item.templateId ? (
                        <i className="load-loader loader icon" />
                      ) : (
                        <i
                          className="load-delete-icon icon icon-delete"
                          style={{
                            cursor: !deleteLoading ? "pointer" : "default",
                          }}
                        />
                      )}
                    </button>
                  </li>
                ))
              ) : (
                <li className="modal-excel-files-list-item">{ANY_FILES}</li>
              )}
            </ul>
          )}
        </div>
      </PortalModal>
    </>
  );
};
